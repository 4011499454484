import React, { Fragment, useId, useState } from 'react';
import { gray1100, gray500 } from '@mentimeter/ragnar-colors';
import { Tooltip } from '../Tooltip';
import { Clickable } from '../Clickable';
import { Box, type BoxT } from '../Box';
import { PopoverContainer, PopoverRoot, PopoverTrigger } from '../PopoverNew';
import type { BadgeRibbonT } from '../Ribbon';
import type { AvatarT } from './Avatar';
import { Avatar } from './Avatar';

// This method summarizes a number with a metric suffix
// Ex: 27364 => 27k
const summarizeDisplayNumber = (val: number) => {
  const decimalCount = val.toFixed().length - 1;
  const suffixIndex = Math.floor(decimalCount / 3);
  const suffix = ['', 'k', 'M', 'B', 'T'][suffixIndex];

  if (!suffix) return val.toFixed();

  const number = (
    val / Math.pow(10, Math.floor(decimalCount / 3) * 3)
  ).toFixed();

  return number + suffix;
};

const AvatarNumber = ({
  displayNumber,
  popOverContent,
  size = 'default',
  ...rest
}: BoxT & {
  displayNumber: number;
  popOverContent?: React.ReactNode | undefined;
  size?: AvatarT['size'];
}) => {
  const [show, setShow] = useState(false);
  const referenceId = useId();

  const avatar = (
    <Avatar
      initials={`+${summarizeDisplayNumber(displayNumber)}`}
      data-testid="avatar-number"
      type="user"
      bg={gray500}
      color={gray1100}
      aria-label={popOverContent ? 'total avatars' : undefined}
      borderWidth={2}
      borderColor="bg"
      borderStyle="solid"
      size={size}
      {...rest}
    />
  );

  if (!popOverContent) {
    return avatar;
  }

  return (
    <PopoverRoot>
      <PopoverTrigger>
        <Clickable
          flexDirection="row"
          id={referenceId}
          onClick={() => setShow(!show)}
          borderRadius="full"
          mx="-space1"
          extend={() => ({
            '@media (hover: hover)': {
              ':hover': {
                opacity: 1,
              },
            },
          })}
        >
          {avatar}
        </Clickable>
      </PopoverTrigger>

      <PopoverContainer>{popOverContent}</PopoverContainer>
    </PopoverRoot>
  );
};

export const AvatarButton = ({
  label,
  onClick,
  badge,
  size = 'default',
}: {
  label: string;
  onClick: () => void;
  badge?: BadgeRibbonT | undefined;
  size?: AvatarT['size'];
}) => {
  return (
    <Clickable flexDirection="row" onClick={onClick} mx="-space1">
      <Avatar
        initials={label}
        type="user"
        badge={badge}
        bg="borderPrimaryWeakest"
        aria-label={label}
        borderWidth={2}
        borderColor="bg"
        borderStyle="solid"
        size={size}
      />
    </Clickable>
  );
};

const AvatarStackedCircle = ({
  itemId,
  initials,
  name,
  type,
  profilePictureUrl,
  ...rest
}: AvatarT) => {
  const referenceId = useId();

  return (
    <>
      <Avatar
        initials={initials}
        name={name}
        itemId={String(itemId)}
        type={type || 'user'}
        profilePictureUrl={profilePictureUrl}
        borderWidth={2}
        borderColor="bg"
        fillColor={true}
        borderStyle={type === 'user' ? 'solid' : undefined}
        mx="-space1"
        {...rest}
        id={referenceId}
      />

      {name && (
        <Tooltip
          placement="bottom"
          referenceId={referenceId}
          description={name}
        />
      )}
    </>
  );
};

export interface AvatarsStackedT extends Omit<AvatarT, 'initials'> {
  avatars: AvatarT[];
  maxItems?: number;
  totalItems?: number;
  popOverContent?: React.ReactNode;
  button?: React.ReactNode;
}

export const AvatarsStacked = ({
  avatars,
  maxItems = 5,
  totalItems,
  popOverContent,
  button,
  size = 'default',
  ...rest
}: AvatarsStackedT) => {
  const displayedItems = avatars.slice(0, maxItems);
  return (
    <Box flexDirection="row">
      {displayedItems.map((avatar) => (
        <Fragment key={`avatar-stacked-circle-${avatar.itemId}`}>
          <AvatarStackedCircle size={size} {...avatar} {...rest} />
        </Fragment>
      ))}

      {totalItems && totalItems > maxItems && (
        <AvatarNumber
          displayNumber={totalItems - displayedItems.length}
          popOverContent={popOverContent}
          size={size}
          {...rest}
        />
      )}
      {button}
    </Box>
  );
};
